body {
	margin: 0;
	font-family: "Segoe UI", sans-serif;
	/* font-family: Menlo, Monaco, Consolas, "Courier New", monospace;*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	padding-top: 3.5rem;
	padding-bottom: 2.5rem;
}

.custom-jumbotron {
	background-color: black;
	min-width: 100vw;
}

.custom_p_dark {
	text-align: left;
	font-size: 1.313rem;
	padding-top: 2.2rem;
	color: white;
}

.custom_h_dark {
	color: white;
	font-size: 2.25rem;
	padding-top: 1.2rem;
	margin-top: 2.2rem;
	margin-bottom: 2.2rem;
	margin-right: 1.2rem;
}

.custom_p_white {
	text-align: center;
	font-size: 1.25rem;
	margin-right: 2.2rem;
	margin-left: 2.2rem;
	padding-bottom: 2.2rem;
}

.custom_h_white {
	text-align: center;
	font-size: 2.5rem;
	line-height: 2.2;
	color: rgb(0, 0, 0);
}

.custom_div {
	background-color: #fff5eb;
	box-shadow: 0 0.7rem 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
	margin-bottom: 2.2rem;
	margin-top: 2.2rem;
	border-radius: 0 0 1rem 1rem;
}

.custom_col {
	max-width: 100%;
	align-self: flex-end;
}

.titel_col {
	min-height: 38vw;
}

.title_box {
	background-color: rgb(0, 0, 0, 0.7);
}

.title_box_h1 {
	color: white;
	font-size: 3.75rem;
	line-height: 1.4;
}

.title_box_p {
	color: white;
	font-size: 1.875rem;
	line-height: 1.875;
}

.intention_box {
	background-color: #ffffff;
	border-radius: 120px 60px 120px;
}

.intention_box_h3 {
	color: #bc3434;
	text-align: center;
	line-height: 1.875;
	padding: 1rem;
}

.intention_box_p {
	text-align: center;
	font-size: 1.25rem;
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	padding-bottom: 2.5rem;
}

.accordion_card_header {
	background-color: lightslategrey;
	text-align: center;
	color: #ffffff;
	font-size: 1.5rem;
}

.createButton {
	background-color: #6499a4;
	border-color: #6499a4;
	color: white;
}

.editButton {
	background-color: #dee6de;
}

.editButton:hover {
	background-color: #bfdbbf;
	border-color: #bfdbbf;
}

.deleteButton {
	background-color: #e6cbcb;
}

.deleteButton:hover {
	background-color: #e4a3a3;
	border-color: #e6cbcb;
}

.listButton {
	background-color: #cbd6e6;
	border-color: #cbd6e6;
}

.listButton:hover {
	background-color: #a6bee4;
	border-color: #a6bee4;
}

.rounded-upperleft {
	border-radius: 0.5rem 0 0 0;
}

.rounded-upperright {
	border-radius: 0 0.5rem 0 0;
}

.rounded-top {
	border-radius: 0.5rem 0.5rem 0 0;
}

.testForm {
	text-align: center;
	color: white;
	/* background-color: #005B8B; */
	background-color: #a2b4b8;
	padding: 1rem;
	/* border: #000000 1px solid; */
	border-radius: 1rem;
	font-size: 1.6rem;
}

.lableform {
	text-align: center;
	font-size: 1.3rem;
}

.borderPlayer :only-child {
	border-style: solid;
	border-width: 2px;
	border-radius: 0.4rem;
	border-color: #000000;
}

.borderPlayer {
	margin-bottom: 3rem;
}

.carousel {
	margin-bottom: 0;
}

/* 82em = 1312px */

@media all and (max-width: 82em) {
	.custom-jumbotron {
		background-color: rgb(0, 0, 0);
		min-width: 100%;
		min-height: 51em;
	}
	.custom_col {
		max-width: 100%;
	}
	.titel_col {
		flex-direction: column;
		row-gap: 1rem;
		align-content: center;
	}
	.Card_row {
		row-gap: 1rem;
		align-content: center;
	}
	.title_box {
		width: 100%;
		margin-top: 20rem;
	}
	.intention_box {
		background-color: #ffffff;
		border-radius: 0;
		min-width: 100%;
		margin-top: 0.5rem;
	}
	.risk_col {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 45%;
		max-width: 100%;
	}
}

.nonlink:hover {
	color: #ffffff;
	text-decoration: none !important;
}

/* 75em = 1200px */

@media all and (max-width: 75em) {
	.custom-jumbotron {
		background-color: rgb(0, 0, 0);
		min-width: 100%;
		min-height: 53em;
	}
	.custom_col {
		max-width: 100%;
	}
	.titel_col {
		flex-direction: column;
		row-gap: 1rem;
		align-content: center;
	}
	.Card_row {
		row-gap: 1rem;
		align-content: center;
	}
	.title_box {
		width: 100%;
		margin-top: 20rem;
	}
	.intention_box {
		background-color: #ffffff;
		border-radius: 0;
		min-width: 100%;
		margin-top: 0.5rem;
	}
	.risk_col {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 50%;
		max-width: 100%;
	}
}

/* 62em = 992px */

@media all and (max-width: 62em) {
	.custom_col {
		max-width: 100%;
	}
	.titel_col {
		flex-direction: column;
		row-gap: 1rem;
		align-content: center;
	}
	.Card_row {
		flex-direction: column;
		row-gap: 1rem;
		align-content: center;
	}
	.custom-jumbotron {
		background-color: black;
		min-width: 100vw;
		min-height: 58em;
	}
	.title_box {
		margin-top: 15rem;
	}
	.intention_box {
		background-color: #ffffff;
		margin-top: 2rem;
	}
}

.borderTheme:hover {
	border-color: cornflowerblue;
	border-width: 2px;
}

.borderTheme {
	border-color: darkgray;
	cursor: pointer;
	border-width: 2px;
}

.closeIcon {
	transform: rotateY(180deg);
}

.privacyPolicyText {
	color: #1873ff;
	cursor: pointer;
}

.privacyPolicyText:hover {
	text-decoration: underline;
	color: #0056b3;
}

.header-topic:after {
	content: "";
	display: block;
	left: 15px;
	height: 1px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	max-width: 20%; /* or 100px */
	border-bottom: 2px solid #0069d9;
}

.header-topic-text {
	font-size: 1.25rem;
}

.my_carousel li {
	background-color: black;
}

.carousel span {
	color: green;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	filter: invert(100%);
}

/*.carousel .carousel-control-next-icon {*/
/*    background-color: #0056b3;*/
/*    fill: orange;*/
/*}*/

/*.carousel .carousel-control-prev-icon {*/
/*    background-color: #0056b3;*/
/*    fill: orange;*/
/*}*/

.carousel .carousel-indicators li {
	background-color: black;
}

.carousel .carousel-indicators li.active {
	background-color: black;
}

.carousel .carousel-indicators ol {
	padding-top: 20px;
}

.carousel-indicators {
	bottom: -50px;
}

.carousel-image {
	width: 100%;
	height: 360px;
	object-fit: cover;
}

.login-register-text {
	padding-top: 2.5rem;
}

.login-register-button {
	min-width: 140px;
	margin : 10px;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

#page-container {
	position: relative;
	min-height: 100vh;
}

.priority-topic-container {
	max-width: 1170px;
}

.priority-topic-border {
	margin-bottom: 0;
	border-width: 5px;
	border-color: lightgray;
	padding: 10px;
}

.priority-topic-icon-opened {
	transform: rotate(180deg);
}

.priority-topic-icon {
	height: 2.5rem;
	width: 2.5rem;
}

.topic-card-image {
	width: 100%;
	height: 225px;
	object-fit: cover;
}

.topic-selfassessment-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.topic-image-container {
	height: 400px;
}

.card-body p {
	margin-bottom: 0;
}

.priority-three {
	margin-bottom: 5rem;
}

.temp {
	border: 10px solid #cbcbcb;
}

.blur-fx {
	filter: blur(10px);
}

.login-container {
	max-width: 400px;
}

.register-container {
	max-width: 500px;
}

.selfAssessmentContainer {
	max-width: 700px;
	max-height: 1000px;
	padding-top: 1rem;
	padding-bottom: 3rem;
}

.box-blur {
	box-shadow: 0 0 50px #ccc;
}

.customHeight {
	min-height: 92vh;
}

.FontForErrorMsg {
	font-size: 0.9rem;
}

.rangeSlider {
	margin-bottom: 2rem;
}

.selfAssessmentCategory {
	margin-bottom: 2rem;
}

.learningPageImage {
	padding: 10px;
	border-bottom: 1px solid lightgrey;
}

/* MINDMAP */
.col .svgmindmap {
	border-color: lightgrey;
	border-radius: 1rem;
	border-width: 1px;
	border-style: solid;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

g path.markmap-link {
	stroke: var(--mmcolor);
}

g g line {
	stroke: var(--mmcolor);
}

g g.markmap-node.markmap-fold circle {
	stroke: var(--mmcolor);
	fill: var(--mmcolor);
}

g g.markmap-node circle {
	stroke: var(--mmcolor);
	fill: white;
}

:root {
	--mmcolor: #1f76b4;
}

form.align-text-feedback {
	/* width: "500px"; */
	text-align: center;
}

.fadeOut {
	opacity: 0;
	width: 0;
	height: 0;
	transition: width 0.25s 0.25s, height 0.25s 0.25s, opacity 0.25s;
}
.fadeIn {
	opacity: 1;
	transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}
.custom-modal{
	position: absolute;
	top: 10%;
	left: 20%;
	right: 20%;
	bottom: 6%;
	border: 2px solid #000000;
	overflow: auto;
	border-radius: 4px;
	outline: none;
	padding: 3%;
}

.invisible {
	display: none;
}

.testHeight {
	min-height: 25vh;
}

.testContainer {
	max-width: 1000px;
	max-height: 1000px;
	padding-top: 1rem;
	padding-bottom: 3rem;
}

.red {
	background-color: #be2528;
	color: white;
	/*color: black;*/
	/*border: 0.2rem solid #be2528;*/
}

.green {
	background-color: #a2be25;
	color: white;
	/*color: black;*/
	/*border: 0.2rem solid #a2be25;*/

}

.testReviewHeaderFs {
	font-size: x-large;
	padding-bottom: 0.5rem;
	font-weight: bold;
}

.testReviewQuestionFs {
	font-size: larger;
	padding-bottom: 0.2rem;
}

.testReviewQuestionIndex {
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
	border-right: 0.25rem solid white;
}

.testReviewPoints {
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	border-left: 0.25rem solid white;
	font-weight: bold;
}

.reviewContainer {
	max-width: 1200px;
	max-height: 1000px;
	padding-top: 1rem;
	padding-bottom: 3rem;
}

.accumulatedPointsElement {
	margin-left: auto;
	font-size: larger;
	font-weight: bold;
}

.correctAnswers {
	font-size: 1.5rem;
}

.accumulatedPoints {
	border-bottom: 0.2rem solid gray;
	margin-bottom: 2rem;
}

.topic-card {
	height: calc(100% - 1.5rem);
}

.favorite-icon {
	height: 32px;
	width: 32px;
	position: absolute;
	top: 16px;
	right: 16px;
	color: #FFC300;
}

.favorite-icon:hover {
	height: 36px;
	width: 36px;
	top: 14px;
	right: 14px;
	color: yellow;
}

.favorite-icon-background {
	height: 32px;
	width: 32px;
	position: absolute;
	top: 16px;
	right: 16px;
	color: white;
}

.statistic {
	background: white;
}

@media only screen and (min-width: 576px) {
	.statistic-zoomed {
		position: fixed;
		overflow: auto;
		z-index: 1;
		top: 10%;
		left: 10%;
		height: fit-content;
		max-height: 85%;
		width: 80%;
		text-align: center;
	}
}

.zoomButton {
	background: white;
	border: white;
	position: absolute;
	top: 0;
	right: 0;
	margin: 1%;
}

.personalization-container {
	max-width: 550px;
}

.button-new,
.btn-group-new .btn-primary,
.progress-bar-new {
	background: linear-gradient(155deg,#94e2d2 40%,#85e3fd 60%) !important;
	border-color: #85e3fd !important;
}

.button-new:hover,
.button-new:not(:disabled):not(.disabled):active,
.btn-group-new .btn-primary:not(:disabled):not(.disabled):active {
	background: linear-gradient(175deg,#94e2d2 40%,#85e3fd 60%) !important;
}

.button-new:focus,
.btn-group-new .btn-primary:focus {
	box-shadow: 0 0 0 0.2rem rgba(133, 227, 253, 0.5) !important;
}

.btn-group-new .btn {
	border-radius: 10px;
}

.progress-bar-new.progress-bar-new--success {
	background: #0e8b00 !important;
}