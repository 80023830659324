.App {
    font-family: sans-serif;
    text-align: center;
}

.quizcontent {
    width: 60rem;
}
.quiz-container-padding {
    margin-bottom: 1rem;
}

.quiz-headline-padding {
    margin-top: 2rem;
    margin-bottom: 3rem;
}

#success-container{
    display: flex;
    justify-content: space-evenly;
    padding: 2em 0em 1em;
    align-items: center;
    text-align: center;
}


.quiz-reload-btn {
    width: 3em;
    height: 3em;
    border-radius: 0.7em;
    background: lightgrey;
}

#fail-container{
    display: flex;
    justify-content: space-evenly;
    padding: 2em 20em 2em 20em;
    align-items: center;
}

h2#fail-headline{
    padding-top: 1em;
}


#question-quiz-container{
    display: flex;
    justify-content: space-between;
    padding: 2em 1em 2em 1em;
    align-items: center;
}

#fail-icon{
    padding-top: 0.3em;
    font-size: 4em;
}

#fail-head-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

#startpage-quiz-btn-container{
    display: flex;
    justify-content: space-evenly;
    padding: 0.5em 0 0.5em 0;
    align-items: center;
    gap: 1em;
}
.bereit-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: inherit;
    gap: 20em;
}

.zuruck-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: inherit;
    gap: 1em;
}

.general-quiz-btn {
    border-radius: 0.25rem;
    border: solid transparent;
    min-width: 140px;
    padding: 0.3em 0.5em 0.3em 0.5em;
    font-size: 1.1rem;
}

.fail-reload-btn{
    font-size: 1.5em;
}

@media all and (max-width: 82em) {
    .quizcontent {
        width: 60rem;
    }

    .bereit-container{
        gap: 20em;
    }

    #fail-container{
        padding: 2em 18em 2em 18em;
    }
}

@media all and (max-width: 75em) {
    .quizcontent {
        width: 52rem;
    }

    .bereit-container{
        gap: 18em;
    }

    #fail-container{
        padding: 2em 13em 2em 13em;
    }
}

@media all and (max-width: 62em) {
    .quizcontent {
        width: 36rem;
    }

    .bereit-container{
        gap: 13em;
    }

    .general-quiz-btn {
        padding: 0.3em 0.5em 0.3em 0.5em;
        font-size: 1.2em;
    }


    #startpage-quiz-btn-container{
        gap: 1em;
    }

    #fail-container{
        padding: 2em 8em 2em 8em;
    }
}

@media all and (max-width: 48em) {
    .quizcontent {
        width: 28rem;
    }

    .bereit-container{
        gap: 2em;
    }

    .general-quiz-btn {
        padding: 0.3em 0.5em 0.3em 0.5em;
        font-size: 1em;
    }


    #startpage-quiz-btn-container {
        gap: 1em;
    }

    #fail-container{
        padding: 2em 3em 2em 3em;
    }
}

/*.btn-outline-secondary-quiz {
 max-width: 20rem;
}*/

.countdown-right {
    align-items: center;
    text-align: right;
    color: black;
}

.leben-links {
    align-items: center;
    text-align: left;
    color: black;
}

.btn-outline-secondary-quiz {
    align-items: center;
    text-align: center;
    color: black;
    width: inherit;
    background-color: #bec1c4;
    border-color: #6c757d;
}

.btn-outline-secondary-quiz:hover {
    align-items: center;
    text-align: center;
    color: black;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary-quiz:focus, .btn-outline-secondary-quiz.focus {
    align-items: center;
    text-align: center;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary-quiz:disabled {
    color: black;
}

.btn-outline-secondary-quiz.disabled, .btn-outline-secondary-quiz:disabled {
    text-align: center;
    color: #42474d;
    opacity: 0.8;
    /*background-color: transparent;*/
}

.trophy-icon-left{
    padding-right: 1em;
}

.trophy-icon-right{
    padding-right: 1em;
}

.background-gray{
    padding-top: 0.3em;
    padding-bottom: 0.8em;
}


.btn-outline-secondary-quiz:not(:disabled):not(.disabled):active, .btn-outline-secondary-quiz:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary-quiz.dropdown-toggle {
    text-align: center;
    color: black;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary-quiz:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-quiz:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary-quiz.dropdown-toggle:focus {
    text-align: center;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
